import React from 'react'
import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import ImageBox from '../components/image-box'
import Image from '../components/image'

import Abbotsford4 from '../images/Abbotsford/Abbotsford4_dark.jpg'
import Abbotsford5 from '../images/Abbotsford/Abbotsford5.jpg'
import Abbotsford6 from '../images/Abbotsford/Abbotsford6.jpg'
import Abbotsford7 from '../images/Abbotsford/Abbotsford7.jpg'

const AboutPage = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Abbotsford4}
      />
      <CardBox title="About Us" noSpacer center>
        <Card>
          <h3 style={{color: 'black'}}>Eastwood Property works for a variety of private architects and local
          governments including: Moonee Valley, Yarra Ranges, Manningham, Hume.
          At the state government level, Eastwood Property also works for the
          Department of Health and Human Services, and Engineering services.</h3>
          <ImageBox>
            <Image src={Abbotsford5}/>
            <Image src={Abbotsford6}/>
            <Image src={Abbotsford7}/>
          </ImageBox>
          <h3 style={{color: 'black'}}>
          Eastwood Property P/L recognises the value and importance of an accident
          free workplace.
          <br/><br/>
          We understand that "just getting the job done" is not enough. Systems of safe work are
          in place and more importantly, inlaid in the culture of our business over more than 15 years of dramatic
          change in the industry. Our staff are trained, responsive and actively engaged in caring for the Health
          and Safety of our own workers, our customers and the general public.
          <br/><br/>
          Eastwood Property P/L has a significant investment in height safety equipment.
          We have staff trained in erection of Quikstage scaffolding, boom operation and
          Elevated Work Platforms up to 70 metres.
          </h3>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default AboutPage
